import React from 'react'
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'reactstrap'
import Layout from '../components/layout'

const AboutPage = () => (
  <Layout>
    <Container fluid>
      <Helmet title="about" />
      <Row noGutters className="mt-4 text-center">
        <Col>
          <h3>About</h3>
        </Col>
      </Row>
      <Row noGutters>
        <Col>
          <div>
            <h4 className="d-inline">Jonathan Schatz</h4> has spent his life
            making software and music, occasionally simultaneously. For a few
            years he worked with Don Buchla on the 200e series.
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default AboutPage
